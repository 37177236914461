export default {
  props: [
    'category',
    'childCategories',
  ],
  data() {
    return {
      gridLayout: [],
      cssClasses: [],
    }
  },
  methods: {
    /**
     * Slices the relevant items.
     * @param numberOfDisplays
     */
    async prepareDigitalSignageObjects(numberOfDisplays) {
      if (this.childCategories) {
        this.childCategories.slice(0, numberOfDisplays).forEach((c, idx) => {
          this.gridLayout[idx] = {
            idx: idx,
            parentCategoryId: c.id,
            css: this.cssClasses[idx],
          }
        })
      }
    },
  },
}